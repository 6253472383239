/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "返回",
      "从App Store下载",
      "从Google Play下载",
      "图文集",
      "第{{number}}张",
      "下一页",
      "上一页",
      "页面",
      "第{{number}}张，共{{total}}张",
      "不允许售卖或分享我的个人信息",
      "不允许售卖或​分享​我的​个人​信息",
      "Bumble主页",
      "关闭菜单",
      "打开菜单",
      "语言选项",
      "Footer",
      "Bumble的无障碍可访问性",
      "Apple与Apple Logo是Apple Inc.的注册商标",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "工作机会",
      "活动",
      "Google Play以及Google Play标志是Google LLC.的注册商标。",
      "指导原则",
      "投资人",
      "管理Cookies",
      "管理Cookies",
      "现代奴役法案声明",
      "数据采集说明",
      "隐私声明",
      "季节约会指南",
      "条款",
      "应用",
      "华盛顿消费者健康数据隐私政策",
      "关于",
      "大使",
      "嗡嗡",
      "商店",
      "主页",
      "跳过​进入​主界面",
      "Bumble商标",
      "友善待人是最具魅力的。做自己，无顾虑。女性掌握主动权。",
      "首创Make The First Move™",
      "美好快乐的生活离不开健康的人际关系。无论是约会、交友、拓展人际网，Bumble都能让你自信地建立人际关系。",
      "Bumble的重要性",
      "无论是何种恋爱关系，或是关系中的哪一阶段，我们都鼓励人们抱有诚意、自信友善、平等尊重地对待他人。我们欢迎各个性取向的人们使用我们的平台，无论你是想寻找真爱或是想体验人生乐趣。",
      "Bumble的价值观",
      "在Bumble，女性通过“Make the First Move”开启聊天。用户也可以使用Opening Move预设留言，等待对方回复。我们一改传统交友秩序，鼓励从开始的那一刻就开启平等关系。",
      "Bumble的使用规则",
      "关于我们",
      "立即下载",
      "使用APP体验更佳。",
      "我们的功能，​让​线上​约会​也​能​充满​乐趣",
      "2024年2月12日",
      "如何在恋爱关系中拉近情感距离",
      "2024年1月25日",
      "从未使用过约会软件。查看Bumble的使用方法",
      "2024年2月5日",
      "Deception Detector™",
      "公开交友新闻",
      "浏览<b>专属推荐</b>，查看根据你的喜好与过往配对专门为你推送的人选。",
      "专门为你挑选",
      "使用<b>瞌睡模式</b>，按下约会暂停键。我们会在这里一直等待你再次出发。",
      "暂别交友",
      "使用<b>视频通话</b>和<b>语音通话</b>，足不出户收获真实交友体验。",
      "来电啦",
      "显示更多",
      "选择<b>Opening Moves</b>，坐等与你配对的TA开启聊天。",
      "省力多得",
      "使用<b>旅行模式</b>，装上免税小吃，在全球随心配对。",
      "带我一起飞",
      "有人让你怦然心动？使用<b>SuperSwipe</b>，让对方知道你的心意。",
      "赢得对方的关注",
      "给对方送上<b>Compliments</b>，从人群中脱颖而出。",
      "打动对方",
      "使用<b>隐身模式</b>隐藏个人档案，不让上司、亲戚或前任看到你的档案。",
      "隐身行动",
      "随心约会",
      "几个月后，她在这里还认识了她现在的伴娘。",
      "汉娜在Bumble结识了她现在的丈夫。",
      "卡瑞妮和尤良在Bumble相遇。一开始，卡瑞妮被尤良的旅行照片所吸引。现在，他们在一起建立他们的小家。",
      "卡瑞妮和尤良",
      "布莱恩和卡茜在海外工作，但他们在Bumble相遇",
      "布莱恩和卡茜",
      "他们成功了，你也可以",
      "继续，下一步",
      "查看新内容",
      "您值得拥有更好的。因此，我们推出了很多有效方法减轻您的约会压力，增加您的约会机会。",
      "告别择偶标准上的妥协",
      "关于Bumble",
      "我们是唯一一个将女性体验放在第一位的交友软件。当女性被善待时，每个人都能得到善待。",
      "Make the first move™",
      "相识你真正想要交往的人，然后迈出第一步，开启聊天。建立有诚意的配对，以共同喜好为起点积极互动。最重要的是，在过程中收获快乐。",
      "使用Bumble Date遇见意中人",
      "寻找事业上的伙伴。与其他专业人士配对，找到新工作、导师或是进入全新的领域。",
      "使用Bumble Bizz，拓展事业",
      "遇见志同道合的人，或是能够殊途同归的人。无论你身在家乡或身在异乡，结识新朋友，一起体验新鲜事。",
      "使用Bumble For Friends结识新朋友",
      "我们不仅仅是约会交友平台",
      "Bumble作为交友软件，鼓励女性迈出第一步。我们的软件改变了约会的传统方式与理念。我们鼓励创建有意义的恋爱关系与人际网络。立即下载。",
      "Bumble {{social_network}}账号",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "对不起，无法找到该网页。",
      "联系我们",
      "登录",
      "啊，发生了一个错误",
      "(opens in new window)",
      "常见问答",
      "Cookie Policy",
      "Bumble—— The Blog",
      "工作机会",
      "聊天",
      "城市",
      "管理Cookies",
      "版权所有© 2006至今。Bumble。保留所有权力。",
      "交友信赖度报告",
      "Apple与Apple商标为Apple Inc.商标。{{__EOL__}}Google Play与Google Play商标为Google LLC.商标。",
      "立即下载",
      "Facebook",
      "关注我们",
      "帮助中心",
      "Instagram",
      "语言",
      "LinkedIn",
      "Bumble属于Bumble Inc.",
      "隐私政策",
      "Safety Centre",
      "网站导览",
      "现代奴役法案声明",
      "条件与条款",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "天"
            ],
            "singular": [
                  "天"
            ]
      },
      "hour": {
            "plural": [
                  "小时"
            ],
            "singular": [
                  "小时"
            ]
      },
      "minute": {
            "plural": [
                  "分钟"
            ],
            "singular": [
                  "分钟"
            ]
      },
      "month": {
            "plural": [
                  "月"
            ],
            "singular": [
                  "月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
