const DEFAULT_LOCATION = '/global-dating-trends/';
const DEFAULT_PATHNAME = 'global-dating-trends';
const DEFAULT_PATH = `/${DEFAULT_PATHNAME}`;
const DEFAULT_LANG_BASED_PATH = `/[lang]${DEFAULT_PATH}`;
const LOCATION_COUNTRY_PHILILIPPINES = 'phi';
const LOCATION_COUNTRY_PHILILIPPINES_PATH = `/${LOCATION_COUNTRY_PHILILIPPINES}/${DEFAULT_PATHNAME}`;
const LOCATION_COUNTRY_PHILILIPPINES_PATHNAME = `${LOCATION_COUNTRY_PHILILIPPINES}/${DEFAULT_PATHNAME}`;
const LOCATION_COUNTRY_SINGAPORE = 'sg';
const LOCATION_COUNTRY_SINGAPORE_PATH = `/${LOCATION_COUNTRY_SINGAPORE}/${DEFAULT_PATHNAME}`;
const LOCATION_COUNTRY_SINGAPORE_PATHNAME = `${LOCATION_COUNTRY_SINGAPORE}/${DEFAULT_PATHNAME}`;

const GLOBAL_DATING_TRENDS_DEFAULT_LOCATION = DEFAULT_LOCATION;
const GLOBAL_DATING_TRENDS_DEFAULT_PATHNAME = DEFAULT_PATHNAME;
const GLOBAL_DATING_TRENDS_DEFAULT_PATH = DEFAULT_PATH;
const GLOBAL_DATING_TRENDS_DEFAULT_LANG_BASED_PATH = DEFAULT_LANG_BASED_PATH;
const GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES = LOCATION_COUNTRY_PHILILIPPINES;
const GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES_PATH =
    LOCATION_COUNTRY_PHILILIPPINES_PATH;
const GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES_PATHNAME =
    LOCATION_COUNTRY_PHILILIPPINES_PATHNAME;
const GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE = LOCATION_COUNTRY_SINGAPORE;
const GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE_PATH = LOCATION_COUNTRY_SINGAPORE_PATH;
const GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE_PATHNAME =
    LOCATION_COUNTRY_SINGAPORE_PATHNAME;

export {
    GLOBAL_DATING_TRENDS_DEFAULT_LOCATION,
    GLOBAL_DATING_TRENDS_DEFAULT_PATHNAME,
    GLOBAL_DATING_TRENDS_DEFAULT_PATH,
    GLOBAL_DATING_TRENDS_DEFAULT_LANG_BASED_PATH,
    GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES,
    GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES_PATH,
    GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES_PATHNAME,
    GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE,
    GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE_PATH,
    GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE_PATHNAME,
};
