import { rpcFactory, RpcAny, SpecialEventType } from '@bumble/gpb/rpc';
import XhrTransport from '@bumble/gpb/rpc-transport/xhr';

export const DW_ENDPOINT_PATH = '/webapi.phtml';
export const MW_ENDPOINT_PATH = '/mwebapi.phtml';

declare const window: Window & {
    bmaApiUrl: string;
};

const RPC = rpcFactory({
    getRpcUrl() {
        return window.bmaApiUrl;
    },
    getTransport() {
        return new XhrTransport();
    },
    supportsOffline: true,
    shouldLogTransport: false,
});

RpcAny.onSpecialEvent(SpecialEventType.REQ_BEFORE_SEND, (req) => {
    req.request.setHeader('X-Message-type', String(req.command));
    req.request.setHeader('X-Use-Session-Cookie', '1');
});

export type { RpcError } from '@bumble/gpb/rpc';

export { BadooMessage, RpcRequestState, SpecialEventType } from '@bumble/gpb/rpc';

export { RpcAny };

export default RPC;
