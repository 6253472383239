/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Retour",
      "Télécharger sur l'App Store",
      "Télécharger sur Google Play",
      "Carrousel",
      "Diapositive {{number}}",
      "Diapositive suivante",
      "Diapositive précédente",
      "Diapositive",
      "Diapositive {{number}} sur {{total}}",
      "Ne pas vendre ou partager mes données personnelles",
      "Ne pas vendre ou partager mes données personnelles",
      "Page d'accueil de Bumble",
      "Ferme le menu",
      "Ouvre le menu",
      "Choix de la langue",
      "Bas de page",
      "Accessibilité chez Bumble",
      "Apple et le logo d'Apple sont des marques déposées d'Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Carrières",
      "Événements",
      "Google Play et le logo de Google Play sont des marques déposées de Google LLC.",
      "Règlement",
      "Investisseurs",
      "Gérer les témoins",
      "Gérer les témoins",
      "Loi sur l'esclavage moderne",
      "Avis de collecte",
      "Politique de confidentialité",
      "Guides de rencontres saisonniers",
      "Conditions d'utilisation",
      "L'appli",
      "Politique de confidentialité des données de santé du consommateur de Washington",
      "À propos",
      "Ambassadeurs",
      "Le blog",
      "Boutique",
      "Principale",
      "Passer au contenu principal",
      "Logo de Bumble",
      "Ici, la gentillesse est sexy, être soi-même est parfait, et les femmes passent avant tout. Toujours.",
      "Le sanctuaire de Fais le premier pas™",
      "Des relations saines contribuent à une vie productive et positive. Bumble est une plateforme sociale qui te permet d'en établir de la manière la plus gratifiante qui soit, qu'il s'agisse de rencontres amoureuses, d'amitié ou d'opportunités de carrière.",
      "Pourquoi Bumble fait toute la différence",
      "Nous prônons l'intégrité, la gentillesse, l'égalité, la confiance et le respect à tous les stades d'une relation. Nous célébrons toutes les orientations, que tu sois ici pour trouver l'amour ou simplement pour t'amuser.",
      "Les valeurs de Bumble",
      "Sur Bumble, ce sont les femmes qui donnent le ton en faisant le premier pas ou en proposant un premier pas auquel leurs matchs peuvent répondre, changeant ainsi les conventions sociales dépassées et promouvant l'égalité dès le début.",
      "Comment Bumble fonctionne",
      "À propos de nous",
      "Télécharger",
      "C'est mieux dans l'appli.",
      "Des options conçues pour rendre les rencontres en ligne vraiment agréables",
      "12 février 2024",
      "Comment accroître l'intimité émotionnelle dans une relation",
      "25 janvier 2024",
      "Comment utiliser Bumble quand on n'a jamais utilisé d'appli de rencontre",
      "5 février 2024",
      "Deception Detector™",
      "Toute l'actualité du monde des rencontres",
      "Parcoure <b>Pour toi</b> pour voir des profils sélectionnés en fonction de tes préférences et matchs précédents.",
      "Choisis pour toi",
      "Prends un moment pour toi avec le <b>mode Invisible</b>. Quand tu voudras revenir, tu sais où nous trouver.",
      "Fais une pause",
      "Utilise les <b>appels vidéo</b> et les <b>appels audio</b> pour te rapprocher sans quitter la maison.",
      "Dis-le à voix haute",
      "Afficher plus",
      "Choisis des <b>Opening Moves™</b> pour inviter ton match à lancer la discussion et t'éviter d'avoir à le faire.",
      "Moins d'efforts, plus de rencontres",
      "Trouve ton bonheur n'importe où dans le monde grâce au <b>mode Voyage</b>.",
      "Où allons-nous?",
      "Son profil t'intéresse? Fais-lui savoir en lui envoyant un <b>SuperSwipe</b>.",
      "Attire son attention",
      "Envoie des <b>Compliments</b> pour te démarquer des autres.",
      "Donne le sourire",
      "Utilise le <b>mode Incognito</b> pour cacher ton profil à ton patron, tes proches ou tes ex.",
      "En mode furtif",
      "Fais des rencontres à ta façon",
      "Quelques mois plus tard, elle y a également rencontré une de ses demoiselles d'honneur.",
      "Hannah a rencontré son mari sur Bumble.",
      "Avant de construire leur petite maison, Carène et You-Liang se sont rencontrés sur Bumble, où You-Liang a été attiré par les photos de voyage de Carène.",
      "Carène et You-Liang",
      "Brian et Kaci étaient en poste à l'étranger ensemble, mais ils se sont rencontrés sur Bumble.",
      "Brian et Kaci",
      "Si ça a marché pour eux, ça peut marcher pour toi",
      "Passe à l'étape suivante",
      "Voir les nouveautés",
      "Nous proposons d'excellents moyens pour te permettre de faire des rencontres sans stress.",
      "Mets la barre haut",
      "À propos de Bumble",
      "Nous sommes la seule appli qui améliore les rencontres en donnant la priorité à l'expérience des femmes. Parce que quand ça va mieux pour les femmes, ça va mieux pour tout le monde.",
      "Make the first move™",
      "Trouve quelqu'un qui te plaît, puis fais le premier pas. Crée des liens authentiques, discute de vos passions communes, et surtout, amuse-toi bien.",
      "Rencontre quelqu'un avec Bumble Date",
      "Trouve des amis pour t'aider à faire progresser ta carrière. Entre en contact avec d'autres professionnels pour trouver ton prochain emploi, un mentor ou même une nouvelle carrière.",
      "Fais avancer ta carrière avec Bumble Bizz",
      "Rencontre quelqu'un qui te ressemble. Ou pas. Fais-toi des amis et trouvez des activités à faire ensemble, dans ta ville ou à l'étranger.",
      "Fais-toi des amis sur Bumble For Friends",
      "Pas juste pour trouver l'amour",
      "Bumble est l'appli de rencontre où les femmes font le premier pas. C'est l'appli qui a transformé les relations, les amitiés et le réseautage. Télécharge-la maintenant.",
      "Bumble sur {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Nous n'avons pas pu trouver la page que tu cherches.",
      "Nous contacter",
      "Connexion",
      "Une erreur est survenue",
      "(ouvre une nouvelle fenêtre)",
      "FAQ",
      "Politique des cookies",
      "Bumble - Le Blog",
      "Emploi",
      "Chat",
      "Villes",
      "Gérer les cookies",
      "Dépôt légal © 2006 - aujourd'hui. Bumble. Tous droits réservés.",
      "Rapport sur la confiance dans les rencontres",
      "Apple et le logo Apple sont des marques déposées d'Apple Inc.{{__EOL__}}Google Play et le logo Google Play sont des marques déposées de Google LLC.",
      "Télécharger",
      "Facebook",
      "Pour nous suivre :",
      "Centre d'aide",
      "Instagram",
      "Langue",
      "LinkedIn",
      "Bumble fait partie de Bumble Inc.",
      "Politique de Confidentialité",
      "Safety Centre",
      "Plan du site",
      "Loi sur l'esclavage moderne",
      "Conditions Générales d'Utilisation",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "jours"
            ],
            "singular": [
                  "jour"
            ]
      },
      "hour": {
            "plural": [
                  "heures"
            ],
            "singular": [
                  "heure"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "mois"
            ],
            "singular": [
                  "mois"
            ]
      },
      "second": {
            "plural": [
                  "secondes"
            ],
            "singular": [
                  "seconde"
            ]
      },
      "year": {
            "plural": [
                  "ans"
            ],
            "singular": [
                  "an"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
