import { RouteName } from 'containers/routes';

type MapRouteToSeoTitleRecord = { [key in RouteName]: string };

// @TODO - change to lexemes
export enum SeoTitle {
    index = 'Bumble | Date, Chat, Meet New People & Network Better',
    about = 'About - Bumble | Date, Chat, Meet New People & Network Better',
    date = 'Date - Bumble | Date, Chat, Meet New People & Network Better',
}

export const seoTitle: MapRouteToSeoTitleRecord = {
    [RouteName.index]: SeoTitle.index,
    [RouteName.about]: SeoTitle.about,
    [RouteName.date]: SeoTitle.date,
    [RouteName.localisedIndex]: SeoTitle.index,
    [RouteName.localisedAbout]: SeoTitle.about,
    [RouteName.localisedDate]: SeoTitle.date,
};
