import { useEffect } from 'react';

/**
 * Uses "Google approved" way of lazy loading CSS
 * 'deferred-styles' is a noscript tag embedded in the page which the browser doesn't execute for normal environments
 * We just read it and re-add it to the body
 */
export function useLazyLoadCss() {
    useEffect(() => {
        const addStylesNode = global.document.getElementById('deferred-styles');
        if (!addStylesNode) {
            return;
        }

        const linkEl = global.document.createElement('link');
        linkEl.rel = 'stylesheet';
        linkEl.href = addStylesNode.getAttribute('href') || '';
        global.document.body.appendChild(linkEl);
    }, []);
}
