import { RouteName } from 'containers/routes';

type MapRouteToSeoTitleRecord = { [key in RouteName]: string };

// @TODO - change to lexemes
enum SeoDescription {
    index = 'Bumble has changed the way people date, create meaningful relationships & network with women making the first move. Meet new people & download Bumble.',
    about = 'Bumble is a social network that allows you to feel empowered while making connections, whether you’re dating, looking for friends, or growing your network.',
    date = 'There’s never been a better moment to meet new, empowering people. Bumble provides opportunities to safely & easily connect with others via our dating app.',
}

export const seoDescription: MapRouteToSeoTitleRecord = {
    [RouteName.index]: SeoDescription.index,
    [RouteName.about]: SeoDescription.about,
    [RouteName.date]: SeoDescription.date,
    [RouteName.localisedIndex]: SeoDescription.index,
    [RouteName.localisedAbout]: SeoDescription.about,
    [RouteName.localisedDate]: SeoDescription.date,
};
