/* eslint-disable */
export default {
      "onboarding@bumble.incognito.dismiss_warning.cta.cancel": 0,
      "bumblesite@apps-links.app-store.a11y.label": 1,
      "bumblesite@apps-links.google-play.a11y.label": 2,
      "bumblesite@carousel.a11y.description": 3,
      "bumblesite@carousel.navigation.dot.a11y.label": 4,
      "bumblesite@carousel.navigation.next.a11y.label": 5,
      "bumblesite@carousel.navigation.prev.a11y.label": 6,
      "bumblesite@carousel.slide.a11y.description": 7,
      "bumblesite@carousel.slide.a11y.label": 8,
      "bumblesite@ccpa.donotsell.cta": 9,
      "bumblesite@ccpa_cta.title": 10,
      "bumblesite@header.logo.a11y.label": 11,
      "bumblesite@header.navigation.menu.close.a11y.label": 12,
      "bumblesite@header.navigation.menu.open.a11y.label": 13,
      "bumblesite@language-selector.a11y.label": 14,
      "bumblesite@layout.footer.a11y.label": 15,
      "bumblesite@LAYOUT.FOOTER.ACCESSIBILITY_STATEMENT": 16,
      "bumblesite@LAYOUT.FOOTER.Apple.legal_text": 17,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BFF": 18,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BIZZ": 19,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_DATE": 20,
      "bumblesite@LAYOUT.FOOTER.CAREERS": 21,
      "bumblesite@LAYOUT.FOOTER.EVENTS": 22,
      "bumblesite@LAYOUT.FOOTER.Google_play.legal_text": 23,
      "bumblesite@LAYOUT.FOOTER.GUIDELINES": 24,
      "bumblesite@LAYOUT.FOOTER.investors": 25,
      "bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES": 26,
      "bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES.label": 27,
      "bumblesite@LAYOUT.FOOTER.msa": 28,
      "bumblesite@LAYOUT.FOOTER.NoticeAtCollection": 29,
      "bumblesite@LAYOUT.FOOTER.PRIVACY_POLICY": 30,
      "bumblesite@LAYOUT.FOOTER.SEASONAL_DATING_GUIDES.label": 31,
      "bumblesite@LAYOUT.FOOTER.Terms": 32,
      "bumblesite@LAYOUT.FOOTER.THEAPP": 33,
      "bumblesite@LAYOUT.FOOTER.Washington_Consumer_Health_Data_Privacy_Policy.label": 34,
      "bumblesite@LAYOUT.HEADER.ABOUT": 35,
      "bumblesite@LAYOUT.HEADER.AMBASSADORS": 36,
      "bumblesite@LAYOUT.HEADER.BEEHIVE": 37,
      "bumblesite@LAYOUT.HEADER.THE_SHOP": 38,
      "bumblesite@layout.main.a11y.label": 39,
      "bumblesite@layout.skip-to-main-content": 40,
      "bumblesite@logo.a11y.label": 41,
      "bumblesite@rebrand.2024.page.about.one.block.text": 42,
      "bumblesite@rebrand.2024.page.about.one.block.title": 43,
      "bumblesite@rebrand.2024.page.about.two.block.item.one.text": 44,
      "bumblesite@rebrand.2024.page.about.two.block.item.one.title": 45,
      "bumblesite@rebrand.2024.page.about.two.block.item.three.text": 46,
      "bumblesite@rebrand.2024.page.about.two.block.item.three.title": 47,
      "bumblesite@rebrand.2024.page.about.two.block.item.two.text": 48,
      "bumblesite@rebrand.2024.page.about.two.block.item.two.title": 49,
      "bumblesite@rebrand.2024.page.about.two.block.title": 50,
      "bumblesite@rebrand.2024.page.date.one.block.cta-action": 51,
      "bumblesite@rebrand.2024.page.date.one.block.text": 52,
      "bumblesite@rebrand.2024.page.date.one.block.title": 53,
      "bumblesite@rebrand.2024.page.date.three.block.item.one.text": 54,
      "bumblesite@rebrand.2024.page.date.three.block.item.one.title": 55,
      "bumblesite@rebrand.2024.page.date.three.block.item.three.text": 56,
      "bumblesite@rebrand.2024.page.date.three.block.item.three.title": 57,
      "bumblesite@rebrand.2024.page.date.three.block.item.two.text": 58,
      "bumblesite@rebrand.2024.page.date.three.block.item.two.title": 59,
      "bumblesite@rebrand.2024.page.date.three.block.title": 60,
      "bumblesite@rebrand.2024.page.date.two.block.item.eight.text": 61,
      "bumblesite@rebrand.2024.page.date.two.block.item.eight.title": 62,
      "bumblesite@rebrand.2024.page.date.two.block.item.five.text": 63,
      "bumblesite@rebrand.2024.page.date.two.block.item.five.title": 64,
      "bumblesite@rebrand.2024.page.date.two.block.item.four.text": 65,
      "bumblesite@rebrand.2024.page.date.two.block.item.four.title": 66,
      "bumblesite@rebrand.2024.page.date.two.block.item.one.showmore.action": 67,
      "bumblesite@rebrand.2024.page.date.two.block.item.one.text": 68,
      "bumblesite@rebrand.2024.page.date.two.block.item.one.title": 69,
      "bumblesite@rebrand.2024.page.date.two.block.item.seven.text": 70,
      "bumblesite@rebrand.2024.page.date.two.block.item.seven.title": 71,
      "bumblesite@rebrand.2024.page.date.two.block.item.six.text": 72,
      "bumblesite@rebrand.2024.page.date.two.block.item.six.title": 73,
      "bumblesite@rebrand.2024.page.date.two.block.item.three.text": 74,
      "bumblesite@rebrand.2024.page.date.two.block.item.three.title": 75,
      "bumblesite@rebrand.2024.page.date.two.block.item.two.text": 76,
      "bumblesite@rebrand.2024.page.date.two.block.item.two.title": 77,
      "bumblesite@rebrand.2024.page.date.two.block.title": 78,
      "bumblesite@rebrand.2024.page.index.four.block.item.one.text": 79,
      "bumblesite@rebrand.2024.page.index.four.block.item.one.title": 80,
      "bumblesite@rebrand.2024.page.index.four.block.item.three.text": 81,
      "bumblesite@rebrand.2024.page.index.four.block.item.three.title": 82,
      "bumblesite@rebrand.2024.page.index.four.block.item.two.text": 83,
      "bumblesite@rebrand.2024.page.index.four.block.item.two.title": 84,
      "bumblesite@rebrand.2024.page.index.four.block.title": 85,
      "bumblesite@rebrand.2024.page.index.hero.block.title": 86,
      "bumblesite@rebrand.2024.page.index.one.block.action": 87,
      "bumblesite@rebrand.2024.page.index.one.block.text": 88,
      "bumblesite@rebrand.2024.page.index.one.block.title": 89,
      "bumblesite@rebrand.2024.page.index.three.block.action": 90,
      "bumblesite@rebrand.2024.page.index.three.block.text": 91,
      "bumblesite@rebrand.2024.page.index.three.block.title": 92,
      "bumblesite@rebrand.2024.page.index.two.block.item.one.text": 93,
      "bumblesite@rebrand.2024.page.index.two.block.item.one.title": 94,
      "bumblesite@rebrand.2024.page.index.two.block.item.three.text": 95,
      "bumblesite@rebrand.2024.page.index.two.block.item.three.title": 96,
      "bumblesite@rebrand.2024.page.index.two.block.item.two.text": 97,
      "bumblesite@rebrand.2024.page.index.two.block.item.two.title": 98,
      "bumblesite@rebrand.2024.page.index.two.block.title": 99,
      "bumblesite@rebrand.2024.page.seo.text": 100,
      "bumblesite@social-links.item.a11y.label": 101,
      "bumblesite@team_bumble.page_index.footer.links.gender_gap": 102,
      "bumblesite@team_bumble.page_index.footer.links.slavery": 103,
      "bumblesite@team_bumble.page_index.footer.links.terms": 104,
      "bumblesite@team_bumble.page_index.navigation.links.about": 105,
      "bumblesite@team_bumble.page_index.navigation.links.life": 106,
      "bma_client@pages.error.not-found.description": 107,
      "bma_client@pages.faq.section.items.contact_us": 108,
      "bma_client@pages.login-password.submit": 109,
      "bma_client@video_chat.error_title": 110,
      "mw@link.a11y.label.new-window": 111,
      "bumble_mobile_web@company.link.faq": 112,
      "badoo_safety_center@footer.legal_links.cookie_policy": 113,
      "badoo_homepage@footer.blog": 114,
      "badoo_homepage@footer.careers": 115,
      "badoo_homepage@footer.chat": 116,
      "badoo_homepage@footer.cities": 117,
      "badoo_homepage@footer.cookies": 118,
      "badoo_homepage@footer.copyright": 119,
      "badoo_homepage@footer.dating_confidence_report": 120,
      "badoo_homepage@footer.download.copy.text": 121,
      "badoo_homepage@footer.download.title": 122,
      "badoo_homepage@footer.facebook": 123,
      "badoo_homepage@footer.follow_us.title": 124,
      "badoo_homepage@footer.help_centre": 125,
      "badoo_homepage@footer.instagram": 126,
      "badoo_homepage@footer.language.title": 127,
      "badoo_homepage@footer.linkedin": 128,
      "badoo_homepage@footer.part_of": 129,
      "badoo_homepage@footer.privacy": 130,
      "badoo_homepage@footer.safety_centre": 131,
      "badoo_homepage@footer.sitemap": 132,
      "badoo_homepage@footer.slavery_act": 133,
      "badoo_homepage@footer.terms": 134,
      "badoo_homepage@footer.twitter": 135
} as const;
