/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Atrás",
      "Descargar en la App Store",
      "Descargar en Google Play",
      "Carrusel",
      "Diapositiva {{number}}",
      "Siguiente diapositiva",
      "Diapositiva anterior",
      "Diapositiva",
      "Diapositiva {{number}} de {{total}}",
      "No vender ni compartir mi información personal",
      "No vendan o compartan mi información personal",
      "Página principal de Bumble",
      "Cerrar menú",
      "Abrir menú",
      "Selección de idioma",
      "Pie de página",
      "Accesibilidad en Bumble",
      "Apple y el Apple Logo son marcas registradas de Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Empleo",
      "Eventos",
      "Google Play y el logotipo de Google Play son marcas comerciales de Google LLC.",
      "Reglas",
      "Inversores",
      "Gestionar cookies",
      "Gestionar cookies",
      "Declaración sobre la Modern Slavery Act",
      "Notificación de recopilación",
      "Política de privacidad",
      "Guía de citas por estaciones",
      "Condiciones de Uso",
      "La aplicación",
      "Política de privacidad de Washington Consumer Health",
      "Acerca de",
      "Embajadores",
      "Blog",
      "Nuestra Tienda",
      "Principal",
      "Ir al contenido principal",
      "Logotipo de Bumble",
      "Aquí, ser buenos es sexy, ser tal como somos es perfecto, y las mujeres son la prioridad. Siempre.",
      "El lugar donde se Da el primer paso™",
      "Las relaciones sanas son fundamentales para llevar una vida positiva y productiva. Bumble está diseñada para ayudarte a que te empoderes mientras creas nuevas conexiones, tanto si quieres salir con alguien, hacer nuevas amistades o ampliar tu red de contactos profesionales.",
      "Qué hace a Bumble diferente",
      "Defendemos la integridad, la amabilidad, la igualdad, la confianza y el respeto durante todas las etapas de cualquier relación. No solo acogemos, sino que celebramos todas las orientaciones, ya estés aquí para encontrar el amor o simplemente para divertirte.",
      "Los valores de Bumble",
      "En Bumble, las mujeres marcan el ritmo dando el primer paso o proponiendo un Opening Move para que sus conexiones respondan. Así se cambian las viejas dinámicas de poder y se fomenta la igualdad desde el principio.",
      "Cómo funciona Bumble",
      "Conócenos",
      "Descargar ahora",
      "Es mejor en la app.",
      "Funciones diseñadas para que las citas online sean, de verdad, disfrutables.",
      "12 de febrero, 2024",
      "Cómo incrementar la intimidad emocional en tu relación de pareja",
      "25 de enero, 2024",
      "Cómo utilizar Bumble cuando nunca antes has usado una app de citas",
      "5 de febrero, 2024",
      "Deception Detector™",
      "Todas las novedades sobre el mundo de las citas",
      "Explora <b>Para ti</b> y descubre perfiles seleccionados según tus preferencias y conexiones previas.",
      "Seleccionados para ti",
      "Tómate un descanso con el <b>Modo no molestar</b>. Cuando te apetezca volver a las citas, ya sabes dónde estamos.",
      "Tómate un tiempo",
      "Utiliza <b>videollamadas</b> y <b>chats de audio</b> para una experiencia lo más real posible sin salir de casa.",
      "Díselo fuerte",
      "Ver más",
      "Elige <b>Opening Moves</b> para invitar a tu conexión a iniciar el chat, así no tendrás que hacerlo tú.",
      "Menos esfuerzo, más citas",
      "Encuentra tu capricho duty-free y conecta en cualquier parte del mundo con el <b>Modo Travel</b>.",
      "Llévanos contigo",
      "¿Su perfil te encantó? Demuéstraselo con un <b>SuperSwipe</b>.",
      "Llama su atención",
      "Usa <b>Compliments</b> para que sepa por qué te gusta.",
      "Haz que se sonrojen",
      "Utiliza el <b>Modo Incógnito</b> para ocultar tu perfil de tu jefa, familiares o de tu ex.",
      "Perfil secreto",
      "Ten las citas que tú quieres",
      "Unos meses después, también conoció allí a una de sus damas de honor",
      "Hannah conoció a su ahora esposo en Bumble.",
      "Antes de construir su casita juntos, Carène y You-Liang se conocieron en Bumble, donde las fotos de viajes de Carène llamaron la atención de You-Liang.",
      "Carène y You-Liang",
      "Brian y Kaci compartieron destino en el extranjero, pero se conocieron en Bumble",
      "Brian y Kaci",
      "Si funcionó en su caso, podría funcionarte a ti.",
      "Da el siguiente paso",
      "Ver qué hay de nuevo",
      "Mereces lo mejor, así que hemos diseñado formas geniales para que tengas más citas sin tanto estrés.",
      "Vuelve al sitio al que perteneces",
      "Sobre Bumble",
      "Somos la única app que mejora las citas dando prioridad a las experiencias de las mujeres. Porque cuando las cosas mejoran para las mujeres, mejoran para todo el mundo.",
      "Make the first move™",
      "Encuentra a alguien con quien valga la pena salir y Da el primer paso. Haz conexiones auténticas, vibra con lo que os apasiona y, lo más importante, diviértete.",
      "Conoce a alguien con Bumble Date",
      "Amplía tu círculo con beneficios laborales. Conecta con profesionales para encontrar tu próximo trabajo, una mentoría o incluso tu nueva profesión.",
      "Avanza en tu carrera con Bumble Bizz",
      "Conoce a alguien como tú. O diferente. Haz nuevas amistades con las que descubrir cosas nuevas que hacer, en tu ciudad o fuera.",
      "Haz amistades nuevas en Bumble For Friends",
      "Somos mucho más que citas",
      "Bumble es la app de citas donde las mujeres dan el primer paso. Es la app que ha revolucionado la forma de tener citas, crea relaciones significativas y networking. Descárgala ahora.",
      "Bumble en {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Lo sentimos, pero no pudimos encontrar la página que estabas buscando.",
      "Contacto",
      "Entrar",
      "Parece que algo falló...",
      "(se abre en otra ventana)",
      "Preguntas",
      "Política de cookies",
      "Bumble - The Blog",
      "Empleo",
      "Chat",
      "Ciudades",
      "Gestionar cookies",
      "Copyright © 2006 – presente. Bumble. Todos los derechos reservados.",
      "Reporte de Confianza en las citas",
      "Apple y el Logo de Apple son marcas registradas de Apple Inc. {{__EOL__}}Google Play y el logo de Google Play son marcas registradas de Google LLC. ",
      "Descargar",
      "Facebook",
      "Síguenos",
      "Centro de soporte",
      "Instagram",
      "Idioma",
      "LinkedIn",
      "Bumble es parte de Bumble Inc.",
      "Política de privacidad",
      "Centro de seguridad",
      "Mapa del sitio",
      "Declaración del Modern Slavery Act",
      "Términos de uso",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "días"
            ],
            "singular": [
                  "día"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mes"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "años"
            ],
            "singular": [
                  "año"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
