import GelatoClient from '@bumble/gelato-client-browser';
import Cookies from 'js-cookie';
import { GlobalProps } from 'lib/app/global-props';
import { StorageKey } from 'lib/startup/storage-key';

declare const global: {
    window: Window & { appProps?: GlobalProps };
};

const logger = new GelatoClient({
    url: '/jss/js_error.phtml',
    // new token
    token: 'bumble_rebrand',
    environment: process.env.NODE_ENV,
    deviceId: Cookies.get(StorageKey.DEVICE_ID),
    staticVersion: process.env.KUBERNETES_APPLICATION_TAG || global.window?.appProps?.appTag,
});

export default logger;
