import * as React from 'react';
import { FunctionComponent, ReactNode, createContext } from 'react';

import { GlobalProps } from 'lib/app/global-props';

type Props = {
    children: ReactNode;
    value: GlobalProps;
};

export const GlobalPropsContext = createContext<GlobalProps>({
    lang: __DEFAULT_LANG__,
    country: __DEFAULT_COUNTRY__,
});

export const GlobalPropsProvider: FunctionComponent<Props> = ({ children, value }) => {
    return <GlobalPropsContext.Provider value={value}>{children}</GlobalPropsContext.Provider>;
};
