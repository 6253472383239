/* eslint-disable */
const languages = [
  "ru",
  "en",
  "de",
  "fr",
  "es",
  "it",
  "da",
  "fi",
  "hi",
  "ja",
  "ko",
  "nb",
  "nl",
  "pl",
  "pt",
  "sv",
  "zh",
  "es-co",
  "es-ar",
  "en-us",
  "pt-pt",
  "zh-Hant",
  "es-mx",
  "fr-ca",
  "en-in",
  "en-au",
  "en-ca",
  "es-cl"
] as const;
export default languages;
export type SupportedLanguage = typeof languages[number];
