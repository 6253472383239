/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Atrás",
      "Descargar en la App Store",
      "Descargar en Google Play",
      "Carrusel",
      "Diapositiva {{number}}",
      "Próxima diapositiva",
      "Diapositiva anterior",
      "Diapositiva",
      "Diapositiva {{number}} de {{total}}",
      "No vender o compartir mi información personal",
      "No vender o compartir mi información personal",
      "Página principal de Bumble",
      "Cerrá el menú",
      "Abrí el menú",
      "Selección de idioma",
      "Pie de página",
      "Accesibilidad en Bumble",
      "Apple y el logotipo de Apple son marcas registradas de Apple Inc.",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Empleo",
      "Eventos",
      "Google Play y el logotipo de Google Play logo son marcas registradas de Google LLC.",
      "Reglas",
      "Inversores",
      "Gestionar cookies",
      "Gestionar cookies",
      "Declaración sobre la Modern Slavery Act",
      "Aviso de recopilación",
      "Política de privacidad",
      "Consejos sobre citas por temporada",
      "Condiciones de Uso",
      "La aplicación",
      "Política de privacidad de datos de salud del consumidor de Washington",
      "Acerca de",
      "Embajadores",
      "Blog",
      "Nuestra Tienda",
      "Navegación principal",
      "Ir al contenido principal",
      "Logotipo de Bumble",
      "Acá seducís con tu gentileza, podés ser como realmente sos, y las mujeres siempre tienen la iniciativa.",
      "La confianza de poder dar el primer paso™",
      "Las relaciones sanas son fundamentales para llevar una vida productiva y positiva. El objetivo de Bumble es lograr que te empoderes mientras conocés gente nueva, ya sea en una cita, en una salida para hacer nuevas amistades o simplemente para ampliar tu red de contactos profesional.",
      "Por qué Bumble es diferente",
      "Defendemos la integridad, amabilidad, igualdad, confianza y respeto durante todas las etapas de cualquier relación. No solo damos la bienvenida, sino que también celebramos cualquier orientación sexual, ya sea que estés buscando pareja o simplemente quieras pasar un buen momento.",
      "Qué valoramos en Bumble",
      "En Bumble, las mujeres tienen la palabra, ya que son las que dan el primer paso o permiten iniciar la conversación con una Opening Move. Empezá a relacionarte priorizando la igualdad y olvidate de las dinámicas de poder antiguas.",
      "Cómo funciona Bumble",
      "Sobre nosotros",
      "Descargar ahora",
      "En la app todo es mejor.",
      "Funciones diseñadas para pasar un buen momento en las citas en línea",
      "12 de febrero, 2024",
      "Cómo crear más intimidad emocional en tu relación",
      "25 de enero, 2024",
      "Cómo usar Bumble cuando nunca usaste una app de citas",
      "5 de febrero, 2024",
      "Deception Detector™",
      "Todo lo que necesitás saber sobre citas",
      "Buscá la función <b>Para vos</b> y encontrá los perfiles especialmente sugeridos según tus gustos y conexiones previas.",
      "Sugerencias especiales",
      "Usa el <b>Modo no molestar</b> para tomarte un tiempo. Cuando quieras volver a conocer gente nueva, te esperamos.",
      "Tomate un descanso",
      "Usá <b>videollamadas</b> y <b>audiochats</b> para estar más cerca que nunca de tu conexión sin salir de tu casa.",
      "Decilo a los cuatro vientos",
      "Ver más",
      "Usá la opción <b>Opening Moves</b> para que tu conexión te empiece a escribir antes que vos.",
      "Menos es más",
      "Encontrá lo que te hace feliz en cualquier lugar del mundo y conectá con gente de todas partes con el <b>Modo Travel</b>.",
      "Llevanos con vos",
      "Si te gustó mucho un perfil, dejá que se sepa gracias a <b>SuperSwipe</b>.",
      "Llamá su atención",
      "Sorprendé con tus <b>Compliments</b> y marcá la diferencia.",
      "Regalá una sonrisa",
      "Usá el <b>Modo Incógnito</b> para ocultar tu perfil de tu jefe, familiares o exparejas.",
      "Usá la discreción",
      "Relacionate como quieras",
      "Unos meses después, ella también conoció a su dama de honor",
      "Ana conoció a su actual marido en Bumble.",
      "Antes de que se hicieran la casa juntos, Carina y Javier se conocieron en Bumble. En ese momento, Javier se enamoró cuando vio las fotos de los viajes de Carina.",
      "Carina y Javier",
      "Bauti y Camila trabajaban en la misma ciudad en el exterior, pero se conocieron en Bumble",
      "Bauti y Camila",
      "Si funcionó para otras personas, podría funcionar para vos",
      "Da el próximo paso",
      "Ver las novedades",
      "Como te merecés lo mejor, diseñamos nuevas maneras de conocer más gente en un entorno más relajado.",
      "Encontrá lo que te merecés",
      "Acerca de Bumble",
      "Esta es única aplicación que pone la experiencia de las mujeres en primer lugar para mejorar la experiencia de las citas. Porque un mundo mejor para las mujeres es un lugar mejor para todo el mundo.",
      "Make the first move™",
      "Encontrá a alguien que realmente quieras conocer y animate a dar el primer paso. Conectá genuinamente, dejate llevar por lo que sentís y, sobre todo, pasala bien.",
      "Conocé gente con Bumble Date",
      "Conocé amistades con beneficios laborales. Conectá con otros profesionales para encontrar tu próximo trabajo, mentoría o incluso cambiar tu carrera profesional.",
      "Conectá con profesionales gracias a Bumble Bizz",
      "Conocé gente distinta o que esté en la misma que vos. Generá nuevas amistades para compartir nuevas experiencias.",
      "Encontrá nuevas amistades en Bumble For Friends",
      "No es solo una app de citas",
      "Bumble es la app de citas en la que las mujeres dan el primer paso. Es la app que revolucionó la manera de tener citas, y fomentar relaciones y conexiones reales. Descargala ahora.",
      "Bumble en {{social_network}}",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "Lo sentimos, pero no pudimos encontrar la página que estabas buscando.",
      "Contacto",
      "Entrar",
      "Parece que algo falló...",
      "(se abre en otra ventana)",
      "Preguntas",
      "Política de cookies",
      "Bumble - El Blog",
      "Empleos",
      "Chat",
      "Ciudades",
      "Gestionar cookies",
      "Copyright © 2006 – presente. Bumble. Todos los derechos reservados.",
      "Informe de confianza para citas",
      "Apple y el Logo de Apple son marcas registradas de Apple Inc.{{__EOL__}}Google Play y el logo de Google Play son marcas registradas de Google LLC.",
      "Descargar",
      "Facebook",
      "Seguinos",
      "Centro de Soporte",
      "Instagram",
      "Idioma",
      "LinkedIn",
      "Bumble es parte de Bumble Inc.",
      "Política de privacidad",
      "Centro de Seguridad",
      "Mapa del sitio",
      "Declaración sobre la Ley de Esclavitud Moderna",
      "Términos y condiciones",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "días"
            ],
            "singular": [
                  "día"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mes"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "años"
            ],
            "singular": [
                  "año"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
