/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "戻る",
      "App Storeでダウンロード",
      "Google Playで入手",
      "スライドショー",
      "スライド{{number}}",
      "次へ",
      "前へ",
      "スライド",
      "スライド{{total}}枚中{{number}}枚目",
      "個人情報の販売・共有を許可しない",
      "個人情報を販売または共有しない",
      "Bumbleホーム画面",
      "メニューを閉じる",
      "メニューを開く",
      "言語選択",
      "フッター",
      "Bumbleのアクセシビリティ",
      "AppleとAppleロゴは、Apple Inc.の商標です。",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "採用情報",
      "イベント",
      "Google PlayとGoogle Playのロゴは、Google LLCの商標です。",
      "ガイドライン",
      "株主・投資家の方へ",
      "Cookieの管理",
      "Cookieの管理",
      "現代奴隷法に関する声明",
      "回収時の注意事項",
      "プライバシーポリシー",
      "季節ごとのデートガイド",
      "利用規約",
      "アプリ",
      "ワシントン州消費者健康データ・プライバシーポリシー",
      "概要",
      "アンバサダー",
      "ブログ",
      "ショップ",
      "メイン",
      "スキップして本文へ",
      "Bumbleの商標",
      "ここでは、優しいことがクールで、あなたらしくいることが大切で、女性が最優先です。「常に」です。",
      "自分から行動を起こす場所",
      "健全な人間関係は、ポジティブで生産的な人生を送るために重要です。 Bumbleは、恋愛相手や友達探しでも、仕事上のネットワーク開拓でも、つながりを築きながらエンパワーされるようにデザインされています。",
      "Bumbleが配慮する理由",
      "私たちは、誠実さ、優しさ、平等さ、自信、そして人間関係のすべての段階における尊重を大切にしています。恋愛相手を見つけるためでも、ただ楽しみたいだけでも、あらゆる方向性の出会いを歓迎し、祝福します。",
      "Bumbleが大切にしていること",
      "Bumbleでは、女性が自分で行動を起こすか、マッチング相手が返答できるOpening Moveを設定することを選択します。時代遅れの力関係を変え、始まりから対等であることを奨励します。",
      "Bumbleの仕組み",
      "会社概要",
      "今すぐダウンロード",
      "アプリがおすすめ。",
      "オンラインデートを、​そう、​現実的に、​楽しく​する​ため​の​機能",
      "2024年2月12日",
      "人間関係で感情的な親密さを高める方法",
      "2024年1月25日",
      "出会い系アプリを使ったことがない場合のBumbleの使い方",
      "2024年2月5日",
      "Deception Detector™",
      "記録するべき​出会いに​関する​すべての記事",
      "<b>For You</b>を閲覧すると、あなたの好みや過去のマッチング相手に基づいてキューレーションしたプロフィールが表示されます。",
      "あなた​の​ため​に​キューレーション",
      "<b>スヌーズモード</b>で出会いを一時休止できます。再び出会う準備ができた際にはすぐに再開できます。",
      "休憩をしよう",
      "<b>ビデオ通話</b>や<b>音声チャット</b>を使って、家にいながら現実に会いましょう。",
      "声に出してみよう",
      "もっと見る",
      "<b>Opening Moves</b>を選択して、マッチング相手からチャットを開始してもらうようにしましょう。そうすれば、あなたが開始する必要はありません。",
      "やる事を減らして、もっとデートをしよう",
      "<b>トラベルモード</b>で、免税でスイートなお相手をみつけ、世界のあらゆる場所でマッチングしましょう。",
      "一緒に旅をしよう",
      "バイブスに響くプロフィールをみつけたら、<b>SuperSwipe</b>でその思いを伝えましょう。",
      "お相手の注意を引こう",
      "あなたのプロフィールが際立つように、お相手に<b>Compliments</b>を贈ってみましょう。",
      "お相手を喜ばせよう",
      "<b>プライベートモード</b>で上司、親戚、元恋人からプロフィールを隠せます。",
      "プロフィールの非表示設定",
      "思い通りに​出会いを​コントロール",
      "数カ月後、彼女はBumbleで花嫁介添人の一人にも出会いました。",
      "ハンナはBumbleで今の夫と出会いました。",
      "ユーリアンがカレーヌの旅行写真に惹かれて、二人はBumbleで知り合い、小さな家を一緒に建てました。",
      "カレーヌとユーリアン",
      "ブライアンとケイシは共に海外在住中に、Bumbleで知り合いました。",
      "ブライアンとケイシ",
      "誰か​の​役​に​立てた​の​なら、​あなた​に​も​うまく​いく​かも​しれ​ない",
      "次の行動を起こそう",
      "新着情報を見る",
      "もっとデートを楽しめて、かつストレスが軽減できる素晴らしい方法を考案しました。",
      "バー​を​本来​ある​べき​位置​に​戻し​ましょう",
      "Bumbleについて",
      "Bumbleは、女性の体験を第一に考え、より良いデート体験を提供する唯一のアプリです。なぜなら、女性にとって良いことは、誰にとっても良いことだからです。",
      "Make the first move™",
      "実際にデートしたいと思うお相手をみつけて、自分から行動を起こしましょう。本物のつながりを築き、お互いの好きなことで盛り上がり、そして最も重要なのは楽しむことです。",
      "Bumble​ Date​で​出会い​を探そう",
      "仕事に有益な友達をみつけましょう。他のビジネスプロフェッショナルとつながり、次の仕事やメンター、新しいキャリアと出会いましょう。",
      "Bumble​ Bizz​で​キャリア​アップ​を​目指​そう",
      "似ている人や自分とはまったく異なる人と出会いましょう。新しい友達をつくり、一緒にできる新しいことを探すのはどうでしょう？",
      "Bumble​ For​ Friends​で​新しい​友達​を​みつけ​よう",
      "デート​の​ため​だけ​では​あり​ません",
      "Bumbleは、女性が自分から行動を起こして出会うためのマッチングアプリです。デートの仕方、有意義な関係の作り方、ネットワークの作り方を変えたアプリです。今すぐダウンロードしよう。",
      "{{social_network}}のBumble",
      "Gender Pay Gap",
      "Modern Slavery Statement",
      "Terms and Conditions",
      "About",
      "Life",
      "申し訳ありません、お探しのページが見つかりません。",
      "お問い合わせ",
      "ログイン",
      "問題が発生しました",
      "(opens in new window)",
      "よくある質問",
      "Cookie Policy",
      "Bumble ー The Blog",
      "採用情報",
      "チャット",
      "都市",
      "クッキーの管理",
      "著作権 © 2006 – 現在。Bumble（不許複製・禁無断転載）",
      "デートの信頼度レポート",
      "AppleおよびAppleのロゴはApple Inc.の商標です。{{__EOL__}}Google PlayはGoogle LLCの商標です。",
      "ダウンロード",
      "Facebook",
      "フォローはこちらから",
      "ヘルプセンター",
      "Instagram",
      "言語",
      "LinkedIn",
      "BumbleはBumble Incの一部です。",
      "プライバシーポリシー",
      "Safety Centre",
      "サイトマップ",
      "現代奴隷法",
      "利用規約",
      "Twitter"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "日"
            ],
            "singular": [
                  "日"
            ]
      },
      "hour": {
            "plural": [
                  "時間"
            ],
            "singular": [
                  "時間"
            ]
      },
      "minute": {
            "plural": [
                  "分"
            ],
            "singular": [
                  "分"
            ]
      },
      "month": {
            "plural": [
                  "ヶ月"
            ],
            "singular": [
                  "ヶ月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
