import * as React from 'react';
import { useEffect } from 'react';

import collector from '@bumble/api-stats-collector';
import { useGlobalProps } from 'lib/hooks/use-global-props';
import { type SupportedLanguage } from 'lib/lexemes/supported-languages';
import { Provider as LocaleProvider } from 'lib/locale-context';
import Localization, { LocalizationProvider } from 'lib/localization';
import { AppProps } from 'next/app';

import { SeoContainer } from 'containers/seo';

function AppContainer({ Component, pageProps }: AppProps) {
    const globalProps = useGlobalProps();
    const lang = globalProps ? globalProps.lang : pageProps.lang || 'en';
    const lexemes = getLexemes(lang);

    useEffect(() => {
        collector.init();

        return () => {
            collector.destroy();
        };
    }, []);

    return (
        <LocaleProvider value={lang}>
            <LocalizationProvider
                instance={Localization}
                lang={lang}
                entries={lexemes.lexemes}
                commonWords={lexemes.common}
            >
                <SeoContainer lang={lang} />
                <Component {...pageProps} />
            </LocalizationProvider>
        </LocaleProvider>
    );
}

function getLexemes(lang: SupportedLanguage) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require(`lib/lexemes/${lang}`).default;
}

export default AppContainer;
