import { supportedLanguages } from 'lib/supported-languages';
import { ParsedUrlQuery } from 'querystring';

import {
    GLOBAL_DATING_TRENDS_DEFAULT_LOCATION,
    GLOBAL_DATING_TRENDS_DEFAULT_LANG_BASED_PATH,
    GLOBAL_DATING_TRENDS_DEFAULT_PATH,
    GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES_PATH,
    GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE_PATH,
} from 'containers/global-dating-trends/global-dating-trends-constants';
import regionLanguageList from 'containers/global-dating-trends/global-dating-trends-languages-regions.json';
export { regionLanguageList };

const setRegionLanguageListToList = regionLanguageList
    .map(
        (regionLanguageListItem) => regionLanguageListItem.uri,
        // regionLanguageListItem.uri.length > 0 ? regionLanguageListItem.uri : null,
    )
    // @TODO: we might need to filter more values
    .filter((item) => item) as string[];
export { setRegionLanguageListToList };

type RegionLocaleListItem = {
    title: string;
    value: string;
    href?: string;
};

const setRegionLocaleList: () => RegionLocaleListItem[] = () => {
    return regionLanguageList.map(({ name, code }) => ({
        title: name,
        value: code,
        href: `/${code}${GLOBAL_DATING_TRENDS_DEFAULT_LOCATION}`,
    }));
};

export { setRegionLocaleList };

export function getLocaleFromParams(params?: ParsedUrlQuery) {
    const paramLang = String(params?.lang);
    return regionLanguageList.find(
        (regionLanguageListItem) => regionLanguageListItem.code === paramLang,
    )
        ? paramLang
        : null;
}

export function startsWithSupportedLanguage(url?: string) {
    if (!url) {
        return false;
    }

    return supportedLanguages.some((language) => {
        return url.startsWith(`/${language}/`);
    });
}

export const isGlobalTrendsPageCheckPathname = (pathname: string) =>
    pathname === GLOBAL_DATING_TRENDS_DEFAULT_PATH ||
    pathname === GLOBAL_DATING_TRENDS_DEFAULT_LANG_BASED_PATH ||
    pathname === GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES_PATH ||
    pathname === GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE_PATH;

export const isGlobalTrendsRegionPageCheckPathname = (pathname: string) =>
    pathname === GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES_PATH ||
    pathname === GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE_PATH;
