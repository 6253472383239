type SupportedLanguagesType = [
    'da',
    'de',
    'en-au',
    'en-ca',
    'en-in',
    'en-us',
    'en-gb',
    'en',
    'es-ar',
    'es-cl',
    'es-co',
    'es-mx',
    'es',
    'fi',
    'fr-ca',
    'fr',
    'hi',
    'id',
    'it',
    'ja',
    'ko',
    'nb',
    'nl',
    'pl',
    'pt-pt',
    'pt',
    'ru',
    'sv',
    'zh-Hant',
    'zh',
];

export const supportedLanguages: SupportedLanguagesType = [
    'da',
    'de',
    'en-au',
    'en-ca',
    'en-in',
    'en-us',
    'en-gb',
    'en',
    'es-ar',
    'es-cl',
    'es-co',
    'es-mx',
    'es',
    'fi',
    'fr-ca',
    'fr',
    'hi',
    'id',
    'it',
    'ja',
    'ko',
    'nb',
    'nl',
    'pl',
    'pt-pt',
    'pt',
    'ru',
    'sv',
    'zh-Hant',
    'zh',
];

export function startsWithSupportedLanguage(url?: string) {
    if (!url) {
        return false;
    }

    return supportedLanguages.some((language) => {
        return url.startsWith(`/${language}`);
    });
}
